const bannerRouteMapping = {
    "": {
        title: "DDO Audit",
        subtitle: "Real-time Player Concurrency Data and LFM Viewer",
        miniature: false,
        showButtons: true,
    },
    live: {
        title: "Live",
        subtitle: "Server Status and Quick Info",
        miniature: true,
        showButtons: false,
    },
    servers: {
        title: "Servers",
        subtitle: "Server Population, Demographics, and Trends",
        miniature: true,
        showButtons: false,
    },
    grouping: {
        title: "Grouping",
        subtitle: "Live LFM Viewer",
        miniature: true,
        showButtons: false,
    },
    who: {
        title: "Who",
        subtitle: "Live Character Viewer",
        miniature: true,
        showButtons: false,
    },
    trends: {
        title: "Trends",
        subtitle: "Long-term Population Trends",
        miniature: true,
        showButtons: false,
    },
    about: {
        title: "About DDO Audit",
        subtitle: "Real-time Player Concurrency Data and LFM Viewer",
        miniature: false,
        showButtons: true,
    },
    verification: {
        title: "Verification",
        subtitle: "Verify Your Characters",
        miniature: true,
        showButtons: false,
    },
    registration: {
        title: "Registration",
        subtitle: "Register Your Characters",
        miniature: true,
        showButtons: false,
    },
    activity: {
        title: "Activity",
        subtitle: "Detailed Character Activity",
        miniature: true,
        showButtons: false,
    },
    notifications: {
        title: "LFM Notifications",
        subtitle: "Set Up and Manage Notifications",
        miniature: true,
        showButtons: false,
    },
    timers: {
        title: "Timers",
        subtitle: "View and Manage Timers",
        miniature: true,
        showButtons: false,
    },
}

export { bannerRouteMapping }
