const SPRITE_MAP = {
    HEADER_LEFT: {
        x: 0,
        y: 0,
        width: 46,
        height: 27,
    },
    HEADER_RIGHT: {
        x: 46,
        y: 0,
        width: 15,
        height: 27,
    },
    HEADER_BAR: {
        x: 61,
        y: 0,
        width: 87,
        height: 27,
    },
    CONTENT_TOP_LEFT: {
        x: 0,
        y: 27,
        width: 12,
        height: 11,
    },
    CONTENT_TOP_RIGHT: {
        x: 50,
        y: 27,
        width: 11,
        height: 11,
    },
    CONTENT_BOTTOM_LEFT: {
        x: 0,
        y: 46,
        width: 12,
        height: 12,
    },
    CONTENT_BOTTOM_RIGHT: {
        x: 50,
        y: 46,
        width: 11,
        height: 12,
    },
    CONTENT_TOP: {
        x: 12,
        y: 27,
        width: 38,
        height: 11,
    },
    CONTENT_LEFT: {
        x: 0,
        y: 38,
        width: 12,
        height: 8,
    },
    CONTENT_BOTTOM: {
        x: 12,
        y: 46,
        width: 38,
        height: 12,
    },
    CONTENT_RIGHT: {
        x: 50,
        y: 38,
        width: 11,
        height: 8,
    },
    SINGLE_BORDER_TOP_LEFT: {
        x: 7,
        y: 34,
        width: 5,
        height: 4,
    },
    SINGLE_BORDER_TOP_RIGHT: {
        x: 50,
        y: 34,
        width: 4,
        height: 4,
    },
    SINGLE_BORDER_BOTTOM_LEFT: {
        x: 7,
        y: 46,
        width: 5,
        height: 5,
    },
    SINGLE_BORDER_BOTTOM_RIGHT: {
        x: 50,
        y: 46,
        width: 4,
        height: 5,
    },
    SINGLE_BORDER_TOP: {
        x: 12,
        y: 34,
        width: 38,
        height: 4,
    },
    SINGLE_BORDER_LEFT: {
        x: 7,
        y: 38,
        width: 5,
        height: 8,
    },
    SINGLE_BORDER_BOTTOM: {
        x: 12,
        y: 46,
        width: 38,
        height: 5,
    },
    SINGLE_BORDER_RIGHT: {
        x: 50,
        y: 38,
        width: 4,
        height: 8,
    },
    CROWN: {
        x: 193,
        y: 0,
        width: 18,
        height: 18,
    },
    GROUP_VIEW_CHECKBOX: {
        CHECKED: {
            x: 206,
            y: 22,
            width: 22,
            height: 23,
        },
        UNCHECKED: {
            x: 182,
            y: 22,
            width: 23,
            height: 23,
        },
    },
    CLASSES: {
        ALL: {
            x: 0,
            y: 58,
            width: 104,
            height: 62,
        },
        BARBARIAN: {
            x: 0,
            y: 58,
            width: 20,
            height: 20,
        },
        BARD: {
            x: 21,
            y: 58,
            width: 20,
            height: 20,
        },
        CLERIC: {
            x: 42,
            y: 58,
            width: 20,
            height: 20,
        },
        FIGHTER: {
            x: 63,
            y: 58,
            width: 20,
            height: 20,
        },
        PALADIN: {
            x: 84,
            y: 58,
            width: 20,
            height: 20,
        },
        RANGER: {
            x: 0,
            y: 79,
            width: 20,
            height: 20,
        },
        ROGUE: {
            x: 21,
            y: 79,
            width: 20,
            height: 20,
        },
        SORCERER: {
            x: 42,
            y: 79,
            width: 20,
            height: 20,
        },
        WIZARD: {
            x: 63,
            y: 79,
            width: 20,
            height: 20,
        },
        MONK: {
            x: 84,
            y: 79,
            width: 20,
            height: 20,
        },
        FAVORED_SOUL: {
            x: 0,
            y: 100,
            width: 20,
            height: 20,
        },
        ARTIFICER: {
            x: 21,
            y: 100,
            width: 20,
            height: 20,
        },
        DRUID: {
            x: 42,
            y: 100,
            width: 20,
            height: 20,
        },
        WARLOCK: {
            x: 63,
            y: 100,
            width: 20,
            height: 20,
        },
        ALCHEMIST: {
            x: 84,
            y: 100,
            width: 20,
            height: 20,
        },
    },
    RACES: {
        DRAGONBORN: {
            x: 105,
            y: 58,
            width: 18,
            height: 18,
        },
        DROW: {
            x: 123,
            y: 58,
            width: 18,
            height: 18,
        },
        DWARF: {
            x: 141,
            y: 58,
            width: 18,
            height: 18,
        },
        ELF: {
            x: 159,
            y: 58,
            width: 18,
            height: 18,
        },
        GNOME: {
            x: 177,
            y: 58,
            width: 18,
            height: 18,
        },
        HALFLING: {
            x: 195,
            y: 58,
            width: 18,
            height: 18,
        },
        HALF_ELF: {
            x: 105,
            y: 76,
            width: 18,
            height: 18,
        },
        HALF_ORC: {
            x: 123,
            y: 76,
            width: 18,
            height: 18,
        },
        HUMAN: {
            x: 141,
            y: 76,
            width: 18,
            height: 18,
        },
        TIEFLING: {
            x: 159,
            y: 76,
            width: 18,
            height: 18,
        },
        WARFORGED: {
            x: 177,
            y: 76,
            width: 18,
            height: 18,
        },
        AASIMAR: {
            x: 195,
            y: 76,
            width: 18,
            height: 18,
        },
        ELADRIN: {
            x: 105,
            y: 94,
            width: 18,
            height: 18,
        },
        SHIFTER: {
            x: 123,
            y: 94,
            width: 18,
            height: 18,
        },
        TABAXI: {
            x: 141,
            y: 94,
            width: 18,
            height: 18,
        },
        CHAOSMANCER: {
            x: 159,
            y: 94,
            width: 18,
            height: 18,
        },
    },
    SORT_HEADER: {
        LEFT: { x: 149, y: 0, width: 2, height: 21 },
        RIGHT: { x: 168, y: 0, width: 2, height: 21 },
        CENTER: { x: 151, y: 0, width: 17, height: 21 },
    },
    SORT_HEADER_HIGHLIGHTED: {
        LEFT: { x: 171, y: 0, width: 2, height: 21 },
        RIGHT: { x: 190, y: 0, width: 2, height: 21 },
        CENTER: { x: 173, y: 0, width: 17, height: 21 },
    },
    LFM_SORT_ICON: {
        x: 149,
        y: 22,
        width: 15,
        height: 17,
    },
    IN_PARTY_ICON: {
        x: 165,
        y: 22,
        width: 16,
        height: 20,
    },
    CHECKBOX: {
        UNCHECKED: {
            x: 212,
            y: 1,
            width: 16,
            height: 16,
        },
        CHECKED: {
            x: 229,
            y: 1,
            width: 16,
            height: 16,
        },
    },
    CLASS_FILTER: {
        FIGHTER: {
            x: 1,
            y: 120,
            width: 28,
            height: 28,
        },
        PALADIN: {
            x: 30,
            y: 120,
            width: 28,
            height: 28,
        },
        BARBARIAN: {
            x: 59,
            y: 120,
            width: 28,
            height: 28,
        },
        ROGUE: {
            x: 88,
            y: 120,
            width: 28,
            height: 28,
        },
        RANGER: {
            x: 117,
            y: 120,
            width: 28,
            height: 28,
        },
        CLERIC: {
            x: 146,
            y: 120,
            width: 28,
            height: 28,
        },
        WIZARD: {
            x: 175,
            y: 120,
            width: 28,
            height: 28,
        },
        SORCERER: {
            x: 204,
            y: 120,
            width: 28,
            height: 28,
        },
        BARD: {
            x: 233,
            y: 120,
            width: 28,
            height: 28,
        },
        MONK: {
            x: 262,
            y: 120,
            width: 28,
            height: 28,
        },
        FAVORED_SOUL: {
            x: 291,
            y: 120,
            width: 28,
            height: 28,
        },
        ARTIFICER: {
            x: 320,
            y: 120,
            width: 28,
            height: 28,
        },
        DRUID: {
            x: 349,
            y: 120,
            width: 28,
            height: 28,
        },
        WARLOCK: {
            x: 378,
            y: 120,
            width: 28,
            height: 28,
        },
        ALCHEMIST: {
            x: 407,
            y: 120,
            width: 28,
            height: 28,
        },
    },
}

export { SPRITE_MAP }
